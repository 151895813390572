import React, { useLayoutEffect } from 'react';
import { useLocation } from '@reach/router';
import { PAIDY_HOST } from 'src/constants';

// Redirect old urls from /merchant/campaign/* to /campaign/*
export default function Campaign() {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    if (window) {
      window.location.href = `${PAIDY_HOST}/${pathname.replace(
        '/merchant/',
        ''
      )}`;
    }
  }, [pathname]);

  return <></>;
}
